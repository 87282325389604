@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?59dd9c5d2f2cfe8fb94a06cf4a9cc1a4") format("truetype"),
url("./flaticon.woff?59dd9c5d2f2cfe8fb94a06cf4a9cc1a4") format("woff"),
url("./flaticon.woff2?59dd9c5d2f2cfe8fb94a06cf4a9cc1a4") format("woff2"),
url("./flaticon.eot?59dd9c5d2f2cfe8fb94a06cf4a9cc1a4#iefix") format("embedded-opentype"),
url("./flaticon.svg?59dd9c5d2f2cfe8fb94a06cf4a9cc1a4#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-user:before {
    content: "\f101";
}
.flaticon-smartphone:before {
    content: "\f102";
}
.flaticon-email:before {
    content: "\f103";
}
.flaticon-placeholder:before {
    content: "\f104";
}
.flaticon-edit:before {
    content: "\f105";
}
.flaticon-qr-code:before {
    content: "\f106";
}
.flaticon-id-card:before {
    content: "\f107";
}
.flaticon-padlock:before {
    content: "\f108";
}
.flaticon-log-format:before {
    content: "\f109";
}
.flaticon-log-format-1:before {
    content: "\f10a";
}
.flaticon-gear:before {
    content: "\f10b";
}
.flaticon-gear-1:before {
    content: "\f10c";
}
.flaticon-guard:before {
    content: "\f10d";
}
.flaticon-guard-1:before {
    content: "\f10e";
}
.flaticon-job-description:before {
    content: "\f10f";
}
.flaticon-contact-book:before {
    content: "\f110";
}
.flaticon-contact-book-1:before {
    content: "\f111";
}
.flaticon-24-hour-service:before {
    content: "\f112";
}
.flaticon-60-minutes:before {
    content: "\f113";
}
.flaticon-description:before {
    content: "\f114";
}
.flaticon-compass:before {
    content: "\f115";
}
.flaticon-subject:before {
    content: "\f116";
}
.flaticon-employees:before {
    content: "\f117";
}
.flaticon-employees-1:before {
    content: "\f118";
}
.flaticon-emergency-call:before {
    content: "\f119";
}
.flaticon-call:before {
    content: "\f11a";
}
.flaticon-emergency-call-1:before {
    content: "\f11b";
}
.flaticon-police:before {
    content: "\f11c";
}
.flaticon-arrested:before {
    content: "\f11d";
}
.flaticon-checkpoint:before {
    content: "\f11e";
}
.flaticon-checkpoint-1:before {
    content: "\f11f";
}
.flaticon-calendar:before {
    content: "\f120";
}
.flaticon-calendar-1:before {
    content: "\f121";
}
.flaticon-date:before {
    content: "\f122";
}
.flaticon-schedule:before {
    content: "\f123";
}
.flaticon-bell:before {
    content: "\f124";
}
.flaticon-bell-1:before {
    content: "\f125";
}
