// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.input-group-text {
  background-color: white;
}
.permission tr th {
  padding: 0.5%;
}
.permission tr {
  border-top: 1px solid #ebedf3 !important;
}
.permissionTable td {
  border: none;
}

.RichEditor-root {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

@media (max-width: 767px) {
  .table-responsive {
    overflow: visible;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}

@media print {
  .staffCard {
    font-size: 25pt;
  }
}
@media print {
  .patrol {
    h4 {
      font-size: 15pt !important;
    }
    .print {
      display: inline !important;
      font-size: 15pt !important ;
    }
    .printflex {
      display: flex;
    }
    .input {
      display: none;
    }
    .select {
      display: none;
    }
    .textarea {
      display: none;
    }
  }
  .schedulePrint {
    h4 {
      font-size: 15pt !important;
    }

    .printInline {
      display: inline !important;
      font-size: 12pt !important;
    }
    .input {
      display: none;
    }
    .select {
      display: none;
    }
    .textarea {
      display: none;
    }
  }
  .staffProfile {
    padding: 25px;
    h4 {
      padding-top: 55px !important;
      font-size: 25pt !important;
      margin-bottom: 100px !important;
    }
    div {
      font-size: 17pt;
      p {
        font-weight: 600;
      }
    }
  }
  table {
    border: 1px solid #ebedf3;
  }
  .cancel {
    display: none;
  }
  .update {
    display: "none";
  }
  .print {
    font-weight: 500;
    display: flex !important;
    justify-content: space-between !important;
  }
  .print h4 {
    font-size: 17pt;
    font-weight: 600;
  }
  .print .form-group {
    padding-top: 20px !important;
    font-size: 13.5pt;
  }
  .print .col-3:last-of-type {
    display: flex;
    justify-content: center;
  }

  // .print .col-3 {
  //   padding-top: 20px !important;
  //   padding-left: 15px !important ;
  //   padding-right: 50px !important ;
  // }
  .notPrint {
    display: none !important;
  }
  .checkpoints {
    margin-top: -600px !important;
  }
  .routine {
    margin-top: -450px !important;
  }
  .routineCheckpoint {
    margin-top: -750px !important;
  }
}

.w-full {
  width: 100% !important;
}

// .css-2b097c-container {
//   border: 1px solid #e4e6ef;
//   border-radius: 3px;
// }
// .css-1okebmr-indicatorSeparator {
// }
canvas {
  height: 30vh !important;
  width: 90% !important;
}
.labelofDaily {
  font-size: 9px !important;
}
